import "../style/Intro.css"
function Intro() {

    return (
        <div className="introdiv ">

                <div className="" id="introh1">
                <h1 className="font-monospace  font-weight-bold"  data-text="What are you still waiting">
                    <span className="span" data-text="for flying to SUN?">What are you still waiting </span>
                </h1>

                    <div className="spinner-box">
                        <div className="blue-orbit leo">
                        </div>

                        <div className="green-orbit leo">
                        </div>

                        <div className="red-orbit leo">
                        </div>

                        <div className="white-orbit w1 leo">
                        </div>
                        <div className="white-orbit w2 leo">
                        </div>
                        <div className="white-orbit w3 leo">
                        </div>
                    </div>

                </div>
            <div className="d-flex m-5">
                <button className="btn-primary rounded" id="introbtn">
                    <a href="https://tronscan.org/#/token20/TFauYbzGtH6tHEFJ7iTjagt2aAD5vBns4r" className="text-decoration-none text-white"><span className="btnspan">Visit TronScan</span></a>
                </button>
            </div>

        </div>
    );
}

export default Intro;
