import "../style/Page1.css";
import img from "../svg/10.svg";
import iphone from "../svg/iphone2.svg";
function Page2() {
    return(<div>
        <section className="d-flex justify-content-center">
            <h2 className="readyh2">Ready?</h2>
        </section>
        <section className="d-flex justify-content-center flex-column align-content-center mb-5">
            <div className="spacing-box"></div>
            <div className="box box1">
                <div className="box__content">
                    <p className="boxtopp">
                        Nortron is the classiest MEME created on the Tron network.
                    </p>
                </div>
            </div>

            <div className="sticky">
                <img className="img img-fluid w-100" src={iphone} alt="NorTron Token"/>
            </div>
            <div className="box box--two ml-0 ml-md-5">
                <div className="box__content">
                    <p className="text-white">
                        <span className="logo"></span>
                        <span>Hey Nortronians</span>, The Age of Nortron begins. We are flying to the SUN.
                    </p>
                </div>
            </div>
        </section>
    </div>)
}
export default Page2;