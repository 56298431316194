import "../style/Page1.css"
import img from "../svg/10.svg"
import img2 from "../svg/9.svg"
import img4 from "../svg/NORTRON (5).svg"
import img3 from "../svg/8.svg"
import Intro from "./Intro";
import { FaTelegram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";


function Page1() {
    return(
        <div>
            <div className="inrtobacgroud">
            <section className="d-flex justify-content-center ">
                <img className="img img-fluid w-100 mt-lg-5" src={img4} alt="NorTron Token"/>
                <div className="bleed-canvas">
                    <img className="img img-fluid chopsticks" id="treeimg" src={img2} alt="NorTron Token"/>
                    <img className="img img-fluid  bento" id="treeimg" src={img3} alt="NorTron Token"/>
                    <img className="img img-fluid  veribear" id="treeimg" src={img} alt="NorTron Token"/>
                </div>
            </section>
            </div>


            <section>
                <Intro/>
            </section>



            <div className="d-none d-lg-flex  flex-column w-100 align-content-center justify-content-center">
                <h1 className="text-white w-100 d-flex align-content-center justify-content-center mt-lg-5">Join the flight to the SUN</h1>
                <div className="d-flex flex-column flex-lg-row w-100 align-content-center justify-content-center">
                    <a href="https://t.me/nortroncommunity"><FaTelegram className="pageicon m-5"/></a>
                    <a href="https://x.com/nortroncoin?s=21&t=Q8YOMbSpUAm1gyphe0gKTA"><FaXTwitter className="pageicon m-5"/></a>
                </div>

        </div>




        </div>
    )
}
export default Page1;