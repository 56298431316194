import './App.css';
import Navbar from "./Page/Navbar";


function App() {
  return (
    <div className="App">
        <div>
            <Navbar/>
        </div>
    </div>
  );
}

export default App;
