import "../style/Navbar.css";
import logo from "../svg/NORTRON (1).svg";
import Footer from "./Footer";
import Page1 from "./Page1";
import Economic from "./Economic";
import Page2 from "./Page2";
function Navbar() {
    return (
        <div>
        <div className="Navbardiv fixed-top mx-lg-5 mt-lg-5">
            <nav className="navbar navbar-expand-lg navbar-dark ">
                <div className="container-fluid ">
                    <img src={logo} className="img img-fluid mr-5" id="logo" alt=""/>

                    <button className="navbar-toggler bg-transparent" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse flex-row-reverse bd-highlight  " id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item navbartext align-self-center mx-lg-5">
                                <a className="nav-link text-white active" aria-current="page" href="#NorTron">NorTron</a>
                            </li>
                            <li className="nav-item navbartext align-self-center mx-lg-5" >
                                <a className="nav-link text-white" href="#tokenomics">Tokenomics</a>
                            </li>
                            <li className="nav-item navbartext align-self-center mx-lg-5">
                                <a className="nav-link text-white" href="#Footer">Community</a>
                            </li>
                            <li className="d-none nav-item  navbartext align-self-center mx-lg-5 dropdown" id="openclose">
                                <a className="nav-link text-white dropdown-toggle" href="#" id="navbarDropdownMenuLink"
                                   role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    Dropdown link
                                </a>
                                <ul className="dropdown-menu bg-transparent border-0 " aria-labelledby="navbarDropdownMenuLink">
                                    <li><a className="dropdown-item rounded-3 text-secondary"  href="#">Action</a></li>
                                    <li><a className="dropdown-item rounded-3 text-secondary"  href="#">Another action</a></li>
                                    <li><a className="dropdown-item rounded-3 text-secondary" href="#">Something else here</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
        <div id="">
            <Page1/>
        </div>
        <div id="NorTron">
            <Page2/>
        </div>
        <div id="tokenomics">
            <Economic/>
        </div>
        <div id="Footer" className="w-100 bg-dark">
            <Footer/>
        </div>
        </div>
    );
}

export default Navbar;
