import "../style/Economic.css"
import img1 from "../svg/Nortron is the classiest MEME in the TRON ecosystem. (1).svg"
import img2 from "../svg/Nortron is the classiest MEME in the TRON ecosystem. (2).svg"
import img3 from "../svg/Nortron is the classiest MEME in the TRON ecosystem. (3).svg"
import img4 from "../svg/Nortron is the classiest MEME in the TRON ecosystem. (4).svg"
import img5 from "../svg/Nortron is the classiest MEME in the TRON ecosystem. (5).svg"
import img6 from "../svg/Nortron is the classiest MEME in the TRON ecosystem. (6).svg"

function Economic() {
    return(
        <div className="economicdiv d-flex flex-column flex-lg-row align-content-center justify-content-center">
            <img src={img1} className="economicimg economicimg1 " alt="NorTron Token" />
            <img src={img6} className="economicimg economicimg6 " alt="NorTron Token" />
            <img src={img2} className="economicimg economicimg2 " alt="NorTron Token"/>
            <a href="https://tronscan.org/#/token20/TFauYbzGtH6tHEFJ7iTjagt2aAD5vBns4r" className="economicimg economicimg3" ><img src={img3} className="economicimga" alt="NorTron Token"/></a>
            <img src={img4} className="economicimg economicimg4 " alt="NorTron Token"/>
            <img src={img5} className="economicimg economicimg5 " alt="NorTron Token"/>
        </div>
    )
}
export default Economic;