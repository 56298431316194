import logo from "../svg/NORTRON.svg"
import "../style/Footer.css"
import { FaTelegram } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

function Footer() {
    return(
        <div className="footerdiv d-flex flex-lg-row flex-column h-100">
                <div className="footerleft">
                    <img src={logo} className="img-fluid footerimg w-25 mr-lg-5" alt="NorTron Token"/>
                </div>
                <div className="d-flex flex-row h-100 w-50 align-content-center justify-content-center mt-4 ">
                    <a href="https://t.me/nortroncommunity"  className="footericon"><FaTelegram/></a>
                    <a href="https://x.com/nortroncoin?s=21&t=Q8YOMbSpUAm1gyphe0gKTA" className="footericon"><FaXTwitter/></a>
                </div>
        </div>
        )
}
export default Footer;